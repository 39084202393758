<template>
  <div v-if="hasResource('asset_centre_management')">
    <div class="aops-warehouse">
      <div class="aop-searchForm search-form">
        <div class="aop-fix">
          <el-form :inline="true" :model="filter">
            <el-form-item label="内部ID">
              <el-input
                v-model="filter.id"
                placeholder="输入内部ID"
                clearable
                @keyup.enter.native="addByEnterKey"
              ></el-input>
            </el-form-item>
            <el-form-item label="资产名称">
              <el-input
                v-model="filter.name"
                placeholder="输入名称"
                clearable
                @keyup.enter.native="addByEnterKey"
              ></el-input>
            </el-form-item>
            <el-form-item label="移入状态">
              <el-select v-model="filter.status">
                <el-option v-for="item in assetsStatus" :key="item.value" :label="item.label" :value="item.value"/>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="aop-textBtn">
            <span class="aop-clickButton" @click="inquire">查询</span>
            <span class="aop-clearButton aop-clickButton" @click="reset">清空</span>
          </div>
        </div>
      </div>
      <el-table
        v-loading="isTableLoading"
        :data="assetsData"
        class="data-table"
        header-cell-class-name="table-head"
        cell-class-name="table-cell"
      >
        <el-table-column
            v-for="col in tableColumns"
            :key="col.label"
            :prop="col.prop"
            :label="col.label"
            :width="col.width"
            :align="col.align"
            :fixed="col.fixed">
            <template slot-scope="{row}">
                <div v-if="col.prop === 'preview_url'">
                  <img :src="row.preview_url+ '?imageMogr2/thumbnail/x128/ignore-error/1/interlace/1'" class="aop-previewUrl" alt=""/>
                </div>
                <div v-else-if="col.prop === 'operation'" class="pa_icon-btn icon-btn">
                    <el-button
                        size="mini"
                        type="text"
                        @click="handleShowDetail(row)" >
                        <i class="iconfont">&#xe662;</i>查看
                    </el-button>
                    <el-button
                        v-if="(row.is_move + '' === '0') && hasResource('asset_centre_move') "
                        size="mini"
                        type="text"
                        @click="moveAssets(row)">
                        <img src="./move.png" alt="move" class="aop-move"/>移入
                    </el-button>
                </div>
                <div v-else>{{row[col.prop] || '--'}}</div>
            </template>
        </el-table-column>
      </el-table>
      <div v-if="total != 0"  class="aop-pagination">
        <el-pagination
          :current-page.sync="currentPage"
          :page-sizes="[10, 20, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import api from '../../../api/assets';

export default {
    /**
    * 基础数据&定义数据
     @return{*}
    */
    data() {
        return {
            tableColumns: [
                { prop: 'preview_url', label: '缩略图', width: 190, align: 'left', fixed: 'left' },
                { prop: 'id', label: '内部Id', width: 280, align: 'left' },
                { prop: 'name', label: '资产名称', width: 280, align: 'left' },
                { prop: 'operation', label: '操作', width: 280, align: 'left' }
            ],
            assetsStatus: [
                {
                    value: '',
                    label: '全部'
                },
                {
                    value: 0,
                    label: '未移入'
                },
                {
                    value: 1,
                    label: '已移入'
                }
            ],
            isTableLoading: false,
            assetsData: [],
            data: [],
            total: 0,
            pageSize: 10,
            currentPage: 1,
            filter: {
                id: '',
                name: '',
                status: ''
            }
        };
    },
    /**
    * 创建时
    */
    created() {
        this.getAssetsList();
    },
    /**
    * 激活，需要重新初始化
    */
    activated() {
        if (this.$route.meta.keepAlive) {
            this.getAssetsList();
        }
    },
    methods: {
        /**
        * 查询
         * @param {*} e 值
        */
        addByEnterKey(e) {
            if (e.keyCode == 13) {
                this.getAssetsList();
            }
        },
        /**
        * 查询
         * @param {*} val 值
        */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getAssetsList();
        },
        /**
        * 查询
         * @param {*} val 值
        */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getAssetsList();
        },
        /**
        * 重置
        */
        reset() {
            this.currentPage = 1;
            this.filter = {
                id: '',
                name: '',
                status: ''
            };
        },
        /**
        * 查询
        */
        inquire() {
            this.getAssetsList();
        },
        /**
        * 列表数据
           @returns{*}
        */
        getAssetsList() {
            this.isTableLoading = true;
            const data = {
                limit: this.pageSize,
                page: this.currentPage,
                filter: {
                    id: this.filter.id,
                    name: this.filter.name
                }
            };
            try {
                api.assetCentre(data).then((res) => {
                    if (res.data.code === 10200) {
                        this.assetsData = res.data.data.items;
                        this.data = this.assetsData;
                        this.total = res.data.data.total;
                        this.currentPage = res.data.data.page;
                    }
                    this.filterStatus();
                    this.isTableLoading = false;
                });
            } catch (e) {
                return e;
            }
        },
        /**
        * 查看详情
         * @param {*} val 值
        */
        handleShowDetail(val) {
            this.$router.push(`/warehouse-detail?id=${val.id}`);
        },
        /**
        * 移动资产
         * @param {*} val 值
           @returns{*}
        */
        moveAssets(val) {
            const data = {
                id: val.id
            };
            try {
                api.moveAsset(data).then((res) => {
                    if (res.data.code === 10200) {
                        this.$$success('操作成功');
                        this.getAssetsList();
                    }
                });
            } catch (e) {
                return e;
            }
        },
        /**
        * 筛选状态
        */
        filterStatus () {
            if (this.filter.status === 0 || this.filter.status === 1) {
                this.assetsData = this.data.filter((e) => `${e.is_move}` === `${this.filter.status}`);
            } else {
                this.assetsData = this.data;
            }
        }

    }
};
</script>

<style lang="less">
    @import "./index.less";
</style>
